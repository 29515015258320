body {
}
.main {
  font-family: "Lato", sans-serif;
  font-size: 1.25rem;
  text-align: left;
  counter-reset: section;
}
p {
  margin: 50px;
  text-align: justify;
  font-size: 1.25rem;
  text-justify: inter-word;
}

p.logoblock {
  text-align: center;
}

h3 {
  text-align: center;
  font-size: 2.5vh;
}

h3::before {
  counter-increment: section;
  content: "Q" counter(section) ". ";
}

h4 {
  color: grey;
}

.SampleSize {
  text-align: center;
}

#related::before {
  content: "» Related items: ";
}
